import { useState, useCallback } from "react";
import {getLastDaysDate} from "../utils/date";
import _ from 'lodash';

const menuItems = [    
    {
      label: '24 hours',
      value: getLastDaysDate({days: 1})
    },
    {
      label: '3 days',
      value: getLastDaysDate({days: 3})
    },
    {
      label: '7 days',
      value: getLastDaysDate({days: 7})
    },
    {
      label: '15 days',
      value: getLastDaysDate({days: 15})
    },
    {
      label: '30 days',
      value: getLastDaysDate({days: 30})
    },
    {
      label: '60 days',
      value: getLastDaysDate({days: 60})
    },
    {
      label: '90 days',
      value: getLastDaysDate({days: 90})
    },
  ]

const useCommon = () => {
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState(getLastDaysDate({days: 1}));
  const [searchQuery, setSearchQuery] = useState('');

  const daysHandleChange = (event) => {
    setDays(event.target.value);
  };

  const debouncedSearch = useCallback(
    _.debounce((value) => {
      setSearchQuery(value);
    }, 400),
    []
  );

  const searchHandleChange = (event) => {
    debouncedSearch(event.target.value);
  };

  return {
    loading,
    days,
    searchQuery,
    menuItems,
    setLoading,
    daysHandleChange,
    searchHandleChange
  };
};
export default useCommon;
