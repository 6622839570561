import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useCommon from "../../../hooks/common";
import Table from "../../Table";
import { getRequest, putRequest } from "../../../utils/httpRequest";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { PieChart } from "@mui/x-charts/PieChart";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { useTheme } from "@mui/material/styles";
import { useDrawingArea } from "@mui/x-charts/hooks";
import Popover from "../../Popover/StyledPopover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";


const headCells = [
  {
    id: "no",
    numeric: true,
    disablePadding: false,
    label: "No.",
  },
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "First Name",
  },
  {
    id: "lastName",
    numeric: true,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Joined At",
  },
  {
    id: "updatedAt",
    numeric: true,
    disablePadding: false,
    label: "Last Update",
  },
];


const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 20,
  }));
  
  const StyledTile = styled(Box)(({ theme }) => ({
    height: "350px",
    padding: "20px",
    width: "32%",
    backgroundColor: "#1c2024",
  }));
  
  const StyledTitle = styled(Box)(({ theme }) => ({
    paddingBottom: "5px",
  }));


  const getInsights = (insightsCount, totalContracts) => (insightsCount / totalContracts *100).toFixed(1);

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + 40 + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

const Community = (props) => {
  const { loading, setLoading } = useCommon();
  const theme = useTheme();
  const { referralId } = useParams();
  const [community, setCommunity] = useState([]);
  const [metaInfo, setMetaInfo] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});
  const isPopoverOpened = Boolean(anchorEl);

  useEffect(() => {
    if (!referralId) return;
    const getCommunity = async () => {
      try {
        setLoading(true);
        let queryParams = `size=0&joinedByrefId=${referralId}`;
        const {
          code,
          data,
          message = "",
          meta
        } = await getRequest({
          url: `/api/v1/ap/users/getUsers`,
          params: queryParams,
        });
        if (code === 200) {
          setCommunity(data);
          setMetaInfo(meta);
          return;
        }
        throw new Error(message);
      } catch (error) {
        console.log(
          "============>Error while getting Community",
          error.message
        );
      } finally {
        setLoading(false);
      }
    };
    getCommunity();
  }, []);


  const openPopover = ({ event, selectedUser }) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedUser(selectedUser);
  };
  const closePopover = () => {
    setAnchorEl(null);
    setSelectedUser({});
  };

  const changeAmbassador = async () => {
    try {
      const confirmation = window.confirm("Do you really want to replace ambassador")
      if(confirmation){
        setLoading(true);
        closePopover();
        const payload = {referralId, action: 'replace'};
        const {
          code,
          data,
          message = "",
        } = await putRequest({
          url: `/api/v1/ap/users/changeAmbassador/`,
          params: selectedUser._id,
          body: payload,
        });
        if (code === 200) {
          const updatedCommunity = community.filter(usr => usr._id !== selectedUser._id);
          updatedCommunity.push(data)
          setCommunity(updatedCommunity);
          return;
        }
        throw new Error(message);
      }

      return;

    } catch (error) {
      console.log("============>Error while updating user Info", error.message);
    } finally {
      setLoading(false);
    }
  }; 

  const moreItemList = [
    {
      name: 'Replace ambassador',
      onClick: changeAmbassador
    },
  ];

  const chartData = [];
  if (metaInfo.deployedContracts && metaInfo.totalContracts)
    chartData.push({
      id: 0,
      color: "#f2a82c",
      value: getInsights(metaInfo.deployedContracts, metaInfo.totalContracts),
    });
  if (metaInfo.totalContracts && metaInfo.draftedContracts)
    chartData.push({
      id: 1,
      color: "#02b2af",
      value: getInsights(metaInfo.draftedContracts, metaInfo.totalContracts),
    });

 

  const tableBodyContent = () =>
    community.length ? (
      community?.map((row, index) => {
        return (
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell>{index + 1}</TableCell>
            <TableCell align="left">{row.firstName}</TableCell>
            <TableCell align="left">{row.lastName}</TableCell>
            <TableCell align="left">{row.email}</TableCell>
            <TableCell align="left">{`${new Date(
              row.createdAt
            ).toLocaleString()}`}</TableCell>
            {/* <TableCell
              align="left"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <span> {`${new Date(row.updatedAt).toLocaleString()}`}</span>
            </TableCell> */}

            <TableCell
              align="left"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <span> {`${new Date(row.updatedAt).toLocaleString()}`}</span>
              <IconButton
                sx={{ marginLeft: "auto" }}
                onClick={(event) => openPopover({ event, selectedUser: row })}
              >
                <MoreVertIcon sx={{ fontSize: "20px !important" }} />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })
    ) : (
      <TableRow
        key={0}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        {!loading && <TableCell colSpan={6} align="center">No user found</TableCell>}
      </TableRow>
    );

  return (
    <>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Community
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        p="0"
        pb="30px"
      >
        <StyledTile color={"main"}>
          <StyledTitle>Total Contracts Count</StyledTitle>

          <PieChart
            series={[
              {
                data: chartData,
                innerRadius: 60,
                outerRadius: 100,
                paddingAngle: 3,
                cornerRadius: 5,
                cx: 150,
                cy: 150,
              },
            ]}
            slotProps={{ legend: { hidden: true } }}
          >
            <PieCenterLabel>{metaInfo.totalContracts || '-'}</PieCenterLabel>
          </PieChart>
        </StyledTile>

        <StyledTile color={"main"}>
          <StyledTitle>Drafted Contracts</StyledTitle>
          <Gauge
            value={
              ((metaInfo.draftedContracts || 0) / (metaInfo.totalContracts || 1)) * 100
            }
            // value={98.4}
            startAngle={-110}
            endAngle={110}
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 40,
                transform: "translate(0px, 0px)",
              },
              [`& .${gaugeClasses.valueArc}`]: {
                fill: "#02b2af",
              },
              [`& .${gaugeClasses.referenceArc}`]: {
                fill: theme.palette.text.disabled,
              },
            }}
            text={() =>
              metaInfo.draftedContracts
                ? `${metaInfo.draftedContracts || ""} / ${metaInfo.totalContracts || ""} `
                : "-"
            }
          />
        </StyledTile>

        <StyledTile color={"main"}>
          <StyledTitle>Deployed Contracts</StyledTitle>
          <Gauge
            value={
              ((metaInfo.deployedContracts || 0) / (metaInfo.totalContracts || 1)) * 100
            }
            startAngle={-110}
            endAngle={110}
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 40,
                transform: "translate(0px, 0px)",
              },
              [`& .${gaugeClasses.valueArc}`]: {
                fill: "#f2a82c",
              },
              [`& .${gaugeClasses.referenceArc}`]: {
                fill: theme.palette.text.disabled,
              },
            }}
            text={() =>
              metaInfo.deployedContracts
                ? `${metaInfo.deployedContracts || ""} / ${
                    metaInfo.totalContracts || ""
                  } `
                : "-"
            }
          />
        </StyledTile>
      </Box>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, height: "100%" }}>
          <Table
            headCells={headCells}
            loading={loading}
            tableBodyContent={tableBodyContent}
          />
        </Paper>
      </Box>
      <Popover
        open={isPopoverOpened}
        anchorEl={anchorEl}
        list={moreItemList}
        closePopover={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      />
    </>
  );
};

export default Community;
