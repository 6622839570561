import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "../../Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "../../Table/TableToolbar";
import useCommon from "../../../hooks/common";
import { getRequest, putRequest } from "../../../utils/httpRequest";
import { styled } from "@mui/material/styles";
import { PieChart } from "@mui/x-charts/PieChart";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { useTheme } from "@mui/material/styles";
import { useDrawingArea } from "@mui/x-charts/hooks";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Popover from "../../Popover/StyledPopover";

const headCells = [
  {
    id: "no",
    numeric: true,
    disablePadding: false,
    label: "No.",
  },
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "First Name",
  },
  {
    id: "lastName",
    numeric: true,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Joined At",
  },
  {
    id: "updatedAt",
    numeric: true,
    disablePadding: false,
    label: "Last Update",
  },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&.strikethrough > td': {
    textDecoration: 'line-through',
    color: '#fa4e4e'
}}));

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 20
}
));

const StyledTile = styled(Box)(({ theme }) => ({
  height: "350px",
  padding: "20px",
  width: "32%",
  backgroundColor: "#1c2024",
}));

const StyledTitle = styled(Box)(({ theme }) => ({
  paddingBottom: "5px",
}));

const StyledNA = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  alignItems: "center",
  fontSize: "24px",
  fontWeight: 500,
}));

const getInsights = (insightsCount, totalCount) =>
  ((insightsCount / totalCount) * 100).toFixed(1);

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + 40 + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}
const Users = () => {
  const [users, setUsers] = useState([]);
  const [metaInfo, setMetaInfo] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});
  const isPopoverOpened = Boolean(anchorEl);
  const theme = useTheme();
  const {
    loading,
    days,
    searchQuery,
    menuItems,
    setLoading,
    daysHandleChange,
    searchHandleChange,
  } = useCommon();

  useEffect(() => {
    const getUsers = async () => {
      try {
        setLoading(true);
        let queryParams = "size=0";
        if (days !== "All" && !searchQuery)
          queryParams = `${queryParams}&startDate=${days}`;
        if (searchQuery)
          queryParams = `${queryParams}&email_user=${searchQuery}`;
        const {
          code,
          data,
          message = "",
          meta,
        } = await getRequest({
          url: `/api/v1/ap/users/getUsers`,
          params: queryParams,
        });
        if (code === 200) {
          setUsers(data);
          setMetaInfo(meta);
          return;
        }
        throw new Error(message);
      } catch (error) {
        console.log("============>", error.message);
      } finally {
        setLoading(false);
      }
    };
    getUsers();
  }, [days, searchQuery]);

  const tableBodyContent = () =>
    users?.length ? (
      users.map((row, index) => {
        return (
          <StyledTableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 }}}
            className={row?.accountStatus === 'disabled' ? "strikethrough" : ""}
          >
            <TableCell>{index + 1}</TableCell>
            <TableCell align="left">{row.firstName}</TableCell>
            <TableCell align="left">{row.lastName}</TableCell>
            <TableCell align="left">{row.email}</TableCell>
            <TableCell align="left">{`${new Date(
              row.createdAt
            ).toLocaleString()}`}</TableCell>
            <TableCell
              align="left"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <span> {`${new Date(row.updatedAt).toLocaleString()}`}</span>
              <IconButton
                sx={{ marginLeft: "auto" }}
                onClick={(event) => openPopover({ event, selectedUser: row })}
              >
                <MoreVertIcon sx={{ fontSize: "20px !important" }} />
              </IconButton>
            </TableCell>
          </StyledTableRow>
        );
      })
    ) : (
      <TableRow
        key={0}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell colSpan={6} align="center">
          No record found
        </TableCell>
      </TableRow>
    );

  const openPopover = ({ event, selectedUser }) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedUser(selectedUser);
  };

  const closePopover = () => {
    setAnchorEl(null);
    setSelectedUser({});
  };

  const updateUserInfo = async ({ role = "", accountStatus = "" }) => {
    try {
      if(role === "ambassador" && selectedUser?.joinedByrefId){
        const confirmation = window.confirm("This user is EXISTS in another community. Making him Ambassador will REMOVE him from existing COMMUNITY. Do you really want to make him ambassador?")
        if(!confirmation) return;
      }
      setLoading(true);
      closePopover();
      const payload = {};
      if (role) payload["role"] = role;
      else payload["accountStatus"] = accountStatus;
      const {
        code,
        data,
        message = "",
      } = await putRequest({
        url: `/api/v1/ap/users/`,
        params: selectedUser._id,
        body: payload,
      });
      if (code === 200) {
        const updatedList = users.map((user) => {
          if (user._id === data._id) return data;
          return user;
        });
        setUsers(updatedList);
        return;
      }
      throw new Error(message);
    } catch (error) {
      console.log("============>Error while updating user Info", error.message);
    } finally {
      setLoading(false);
    }
  };

  const moreItemList = [
    {
      name:
        !selectedUser?.role || !selectedUser?.role?.includes("ambassador")
          ? "Make an Ambassador"
          : "Revoke as Ambassador",
      onClick: () =>
        updateUserInfo({
          role:
          !selectedUser?.role || !selectedUser?.role?.includes("ambassador")
              ? "ambassador"
              : "user",
        }),
    },
    {
      name: selectedUser?.accountStatus
        ? selectedUser?.accountStatus === "active"
          ? "Disable User"
          : "Active User"
        : "Disable User",
      onClick: () =>
        updateUserInfo({
          accountStatus: selectedUser?.accountStatus
            ? selectedUser?.accountStatus === "active"
              ? "disabled"
              : "active"
            : "disabled",
        }),
    },
  ];

  const pieChartSettings = {
    innerRadius: 60,
    outerRadius: 100,
    paddingAngle: 5,
    cornerRadius: 5,
    cx: 150,
    cy: 150,
  };

  return (
    <>
      <TableToolbar
        callingFrom="users"
        title="Users"
        label="Select Duration"
        days={days}
        menuItems={menuItems}
        daysHandleChange={daysHandleChange}
        searchHandleChange={searchHandleChange}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        p="0"
        pb="30px"
      >
        <StyledTile color={"main"}>
          <StyledTitle>Total Users Count</StyledTitle>

          {days === "All" ? (
            <PieChart
              series={[
                {
                  ...pieChartSettings,
                  data: [
                    {
                      id: 0,
                      color: "#02b2af",
                      value: 100,
                    },
                  ],
                },
              ]}
              slotProps={{ legend: { hidden: true } }}
            >
              <PieCenterLabel>{metaInfo.totalUsers}</PieCenterLabel>{" "}
            </PieChart>
          ) : (
            <PieChart
              series={[
                {
                  ...pieChartSettings,
                  data: [
                    {
                      id: 0,
                      color: "#02b2af",
                      value: getInsights(metaInfo.newUsers, metaInfo.count),
                    },
                    {
                      id: 1,
                      color: "#f2a82c",
                      value: getInsights(
                        metaInfo.activeUsers - metaInfo.newUsers,
                        metaInfo.count
                      ),
                    },
                  ],
                },
              ]}
              slotProps={{ legend: { hidden: true } }}
            >
              <PieCenterLabel>{metaInfo.count}</PieCenterLabel>{" "}
            </PieChart>
          )}
        </StyledTile>

        <StyledTile color={"main"}>
          <StyledTitle>New Active Users</StyledTitle>
          {days === "All" ? (
            <StyledNA> - </StyledNA>
          ) : (
            <Gauge
              value={
                ((metaInfo.newUsers || 0) / (metaInfo.activeUsers || 1)) * 100
              }
              // value={98.4}
              startAngle={-110}
              endAngle={110}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 40,
                  transform: "translate(0px, 0px)",
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: "#02b2af",
                },
                [`& .${gaugeClasses.referenceArc}`]: {
                  fill: theme.palette.text.disabled,
                },
              }}
              text={() =>
                metaInfo.activeUsers && metaInfo.newUsers
                  ? `${metaInfo.newUsers || ""} / ${
                      metaInfo.activeUsers || ""
                    } `
                  : metaInfo.newUsers
                  ? `${metaInfo.newUsers}`
                  : "-"
              }
            />
          )}
        </StyledTile>

        <StyledTile color={"main"}>
          <StyledTitle>Old Active Users</StyledTitle>
          {days === "All" ? (
            <StyledNA> - </StyledNA>
          ) : (
            <Gauge
              value={
                ((metaInfo.activeUsers - metaInfo.newUsers || 0) /
                  (metaInfo.activeUsers || 1)) *
                100
              }
              startAngle={-110}
              endAngle={110}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 40,
                  transform: "translate(0px, 0px)",
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: "#f2a82c",
                },
                [`& .${gaugeClasses.referenceArc}`]: {
                  fill: theme.palette.text.disabled,
                },
              }}
              text={() =>
                metaInfo.activeUsers && metaInfo.newUsers
                  ? `${metaInfo.activeUsers - metaInfo.newUsers || ""} / ${
                      metaInfo.activeUsers || ""
                    } `
                  : metaInfo.activeUsers
                  ? `${metaInfo.activeUsers}`
                  : "-"
              }
            />
          )}
        </StyledTile>
      </Box>

      <Box sx={{ width: "100%", height: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, height: "100%" }}>
          <Table
            headCells={headCells}
            loading={loading}
            tableBodyContent={tableBodyContent}
          />
        </Paper>
      </Box>
      <Popover
        open={isPopoverOpened}
        anchorEl={anchorEl}
        list={moreItemList}
        closePopover={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      />
    </>
  );
};

export default Users;
